import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

export default function LogTemplate({ data }) {
	const { markdownRemark } = data
	const { frontmatter, html, excerpt } = markdownRemark
	return (
		<Layout title={frontmatter.title}>
			<div className="article">
				<Helmet>
					<meta name="description" content={excerpt} />
				</Helmet>
				<h1>{frontmatter.title}</h1>
				<h2 className="post-date">{frontmatter.date}</h2>
				<div className="post-content" dangerouslySetInnerHTML={{ __html: html }} />
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			excerpt(pruneLength: 280)
			frontmatter {
				title
				date(formatString: "MMMM DD, YYYY")
			}
			fields {
				slug
			}
		}
	}
`
